// --- EMAIL CHECK ---

var valid = false;
var emailRegistered = true;

function validateEmail(email) {
    //const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(String(email).toLowerCase());
}

function validEmail() {
    const emailInput = document.getElementById('email').value;

    if (validateEmail(emailInput)) {
        if (valid == true && emailRegistered == false) {
            // E-Mail ist noch nicht registriert
            return true;
        } else {
            return false;
        }
    } else {
        document.getElementById('error').innerHTML =
            'E-Mail Eingabe ist fehlerhaft';
        return false;
    }
}

// window.addEventListener('load', () => {});
async function mailCheck() {
    const emailInput = document.getElementById('email').value;

    if (validateEmail(emailInput)) {
        //console.log('Email-Format ist ok');
        const emailObject = { email: emailInput }; // E-Mail Object als JS-Object für den body-Payload

        const response = await fetch('/search.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailObject),
        }).then((response) => response.json());
        // Fall-Unterscheidung für die Fehlerausgabe
        if (response.response_code == '200') {
            document.getElementById('error').innerHTML =
                'E-Mail ist bereits registriert';
            valid = false;
            emailRegistered = true;
        } else {
            // E-Mail ist noch nicht registriert
            document.getElementById('error').innerHTML = '';
            valid = true;
            emailRegistered = false;
        }
        //console.log(response.response_code);
        //console.log(response.response_desc);
    } else {
        console.log('Email-Format ist NICHT ok');
        document.getElementById('error').innerHTML = '';
    }
}

if (document.getElementById('email')) {
    document.getElementById('email').addEventListener('input', () => {
        mailCheck();
    });

    document
        .getElementById('teilnahme_submit')
        .addEventListener('click', (e) => {
            if (!validEmail()) {
                e.preventDefault();
            }
        });
}
// --- EMAIL CHECK ---
